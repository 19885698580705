$MAIN_COLOR: #ff8200;
$MAIN_COLOR_OPACITY: #ff8200;
$SECONDARY_COLOR: #000000;
$SECONDARY_COLOR_OPACITY: #000000;
$TERTIARY_COLOR: #bfd730;
$MENU_ITEM_ACTIVE: rgba(255, 255, 255, 0.13);
$MENU_BORDER: #ff8200;
$BACKGROUND_COLOR: #161618;
$ERROR_RED: #ff0000;
$BLACK_TEXT_COLOR: #231f20;
$GRAY_TEXT_COLOR: #8a8a8a;
$SECTION_COLOR: #243138;
$MENU_ICON_ACTIVE: #ff8200;
$MENU_CLOSE_ICON_COLOR: white;

$MENU_BACKGROUND: #243138;
$MENU_ICON_ACTIVE: #ff8200;
$MENU_ICON_INACTIVE: white;
$MENU_TEXT_ACTIVE: white;
$MENU_TEXT_INACTIVE: white;
$MENU_LINE_COLOR: black;
$MENU_LOGOUT_TEXT_COLOR: white;
$MENU_LOGOUT_ICON_COLOR: white;
$MENU_OPEN_ICON_COLOR: white;
$MENU_ACTIVE_ITEM_TEXT_WEIGHT: bold;
$MENU_ITEM_HOVER_BACKGROUND: rgba(255, 255, 255, 0.13);

:export {
  MAIN_COLOR: $MAIN_COLOR;
  MAIN_COLOR_OPACITY: $MAIN_COLOR_OPACITY;
  SECONDARY_COLOR: $SECONDARY_COLOR;
  TERTIARY_COLOR: $TERTIARY_COLOR;
  MENU_ITEM_ACTIVE: $MENU_ITEM_ACTIVE;
  MENU_BORDER: $MENU_BORDER;
  BACKGROUND_COLOR: $BACKGROUND_COLOR;
  ERROR_RED: $ERROR_RED;
  SECONDARY_COLOR_OPACITY: $SECONDARY_COLOR_OPACITY;
  BLACK_TEXT_COLOR: $BLACK_TEXT_COLOR;
  GRAY_TEXT_COLOR: $GRAY_TEXT_COLOR;
  SECTION_COLOR: $SECTION_COLOR;
  MENU_ICON_ACTIVE: $MENU_ICON_ACTIVE;
  MENU_CLOSE_ICON_COLOR: $MENU_CLOSE_ICON_COLOR;

  MENU_BACKGROUND: $MENU_BACKGROUND;
  MENU_ICON_ACTIVE: $MENU_ICON_ACTIVE;
  MENU_ICON_INACTIVE: $MENU_ICON_INACTIVE;
  MENU_TEXT_ACTIVE: $MENU_TEXT_ACTIVE;
  MENU_TEXT_INACTIVE: $MENU_TEXT_INACTIVE;
  MENU_LINE_COLOR: $MENU_LINE_COLOR;
  MENU_LOGOUT_TEXT_COLOR: $MENU_LOGOUT_TEXT_COLOR;
  MENU_LOGOUT_ICON_COLOR: $MENU_LOGOUT_ICON_COLOR;
  MENU_OPEN_ICON_COLOR: $MENU_OPEN_ICON_COLOR;
  MENU_ACTIVE_ITEM_TEXT_WEIGHT: $MENU_ACTIVE_ITEM_TEXT_WEIGHT;
  MENU_ITEM_HOVER_BACKGROUND: $MENU_ITEM_HOVER_BACKGROUND;
}

body {
  --MAIN_COLOR: #{$MAIN_COLOR};
  --MAIN_COLOR_OPACITY: #{$MAIN_COLOR_OPACITY};
  --SECONDARY_COLOR: #{$SECONDARY_COLOR};
  --TERTIARY_COLOR: #{$TERTIARY_COLOR};
  --MENU_ITEM_ACTIVE: #{$MENU_ITEM_ACTIVE};
  --MENU_BORDER: #{$MENU_BORDER};
  --BACKGROUND_COLOR: #{$BACKGROUND_COLOR};
  --ERROR_RED: #{$ERROR_RED};
  --SECONDARY_COLOR_OPACITY: #{$SECONDARY_COLOR_OPACITY};
  --BLACK_TEXT_COLOR: #{$BLACK_TEXT_COLOR};
  --GRAY_TEXT_COLOR: #{$GRAY_TEXT_COLOR};

  --MENU_BACKGROUND: #{$MENU_BACKGROUND};
  --MENU_ICON_ACTIVE: #{$MENU_ICON_ACTIVE};
  --MENU_ICON_INACTIVE: #{$MENU_ICON_INACTIVE};
  --MENU_TEXT_ACTIVE: #{$MENU_TEXT_ACTIVE};
  --MENU_TEXT_INACTIVE: #{$MENU_TEXT_INACTIVE};
  --MENU_LINE_COLOR: #{$MENU_LINE_COLOR};
  --MENU_LOGOUT_TEXT_COLOR: #{$MENU_LOGOUT_TEXT_COLOR};
  --MENU_LOGOUT_ICON_COLOR: #{$MENU_LOGOUT_ICON_COLOR};
  --MENU_OPEN_ICON_COLOR: #{$MENU_OPEN_ICON_COLOR};
  --MENU_ACTIVE_ITEM_TEXT_WEIGHT: #{$MENU_ACTIVE_ITEM_TEXT_WEIGHT};
  --MENU_ITEM_HOVER_BACKGROUND: #{$MENU_ITEM_HOVER_BACKGROUND};
}
